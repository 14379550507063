<template>
    <v-dialog v-model="deleteSnapshotDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" icon text color="primary"><v-icon>delete</v-icon></v-btn>
                </template>
                <span>Delete snapshot</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <div style="width:100%" class="d-flex align-center">
                    <v-icon>delete</v-icon>
                    <span class="text-uppercase primary--text ml-1">delete snapshot</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="mt-3">
                    <span class="font-weight-bold">Snapshot Name</span>
                    : {{ getSnapshotName }}
                </div>
                <div class="mt-3">
                    <span class="font-weight-bold">Snapshot Work Phase</span>
                    : {{ getSnapshotDescription }}
                </div>
                <div class="mt-3">
                    <span class="font-weight-bold">Snapshot Timestamp</span>
                    : <span v-if="getSnapshotTimestamp">{{ getSnapshotTimestamp | dateTimeToHuman }}</span>
                </div>
                <v-alert outlined color="error" prominent type="warning" class="mt-5">
                    <span class="font-weight-bold">Here is what happens when you delete a snapshot</span>
                    <ul class="mt-3">
                        <li>
                            <span class="subtitle-2">
                                The contents (files, tables, and application) of the snapshot will be deleted permanently.
                            </span>
                        </li>
                    </ul>
                    <v-divider class="my-4 primary" style="opacity: 0.22"></v-divider>
                    <v-checkbox class="primary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <div style="width:100%" class="d-flex flex-column">
                    <div class="d-flex align-center justify-end">
                        <v-btn color="primary" text @click="deleteSnapshotDialog = false">close</v-btn>
                        <DeleteButton
                            buttonName="delete"
                            objectType="snapshot"
                            :apiURL="'/snapshots/' + $props.snid"
                            :id="parseInt($props.snid, 10)"
                            getterString="snapshotStore/snapshotLongNameById"
                            :higherLevelId="parseInt($route.params.iid, 10)"
                            :routeAfter="
                                $props.snid.toString() === $route.params.snid.toString()
                                    ? {
                                          name: 'snapshot-overview',
                                          params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: developmentSnapshot.snid }
                                      }
                                    : null
                            "
                            fetchString="instanceStore/fetchInstanceSnapshots"
                            @error="error = $event.error"
                            :disabled="!agreeBox"
                            :isInsideDialog="true"
                        ></DeleteButton>
                    </div>
                    <div>
                        <v-alert :value="error" color="error" prominent type="error" class="mt-5" text>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">Snapshot Deletion Error</span>
                                An error has occurred and the snapshot could not be deleted. Please try again later and if the problem persists contact
                                support@alphacruncher.com
                            </div>
                        </v-alert>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
const DeleteButton = () => import('@/components/DeleteButton')

export default {
    name: 'SnapshotDeleteDialog',
    components: { DeleteButton },
    props: {
        snid: String
    },
    data() {
        return {
            agreeBox: false,
            deleteSnapshotDialog: false,
            error: false
        }
    },
    computed: {
        getSnapshotName() {
            return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$props.snid)
        },
        getSnapshotDescription() {
            return this.$store.getters['snapshotStore/snapshotDescriptionById'](this.$props.snid)
        },
        getSnapshotTimestamp() {
            return this.$store.getters['snapshotStore/snapshotTimestampById'](this.$props.snid)
        },
        ...mapGetters('snapshotStore', ['developmentSnapshot'])
    },
    watch: {
        deleteSnapshotDialog: function(nextVal) {
            if (nextVal === false && this.$data.error === true) {
                this.$data.error = false
            }
        }
    }
}
</script>
